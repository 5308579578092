import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController,IonNav } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-trxservices-card',
  templateUrl: './trxservices-card.page.html',
  styleUrls: ['./trxservices-card.page.scss'],
})
export class TrxServicesCardPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  @Input() debtorDetails;
  @Input() totalOutstandingWithSymbol;
  @Input() caseDetails;
  title = 'Lateral Payment Portal';
  TrxServicesForm: FormGroup;
  saveBtnDisabled = false;
  paymentCard;
  isConfigured = true;
  company = '';
  amount = '';
  message = '';
  surcharge_amount = 0;
  fieldLabels: any = {};
  loadedTemplate = false;
  years: number[] = this.generateYearList();
  months: number[] = this.generateMonthList();
  paymentGatewayTemplate;

  constructor(
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav,
    private alertController: AlertController,
  ) {}

  ngOnInit() {
    this.saveBtnDisabled = false;
    this.amount = this.responseData?.net_amount;
    this.company = this.responseData.company;
    this.amount = parseFloat(this.responseData?.net_amount).toFixed(2);
    this.surcharge_amount = this.responseData?.surcharge_amount;
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    this.message = portalSettings.payment_type_message;
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.trxservices && payment_type?.trxservices?.label) {
        this.title = payment_type.trxservices.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    if(portalSettings?.gateway_templates?.trxServices?.card_non_recurring_payment){
      this.paymentGatewayTemplate=portalSettings.gateway_templates.trxServices.card_non_recurring_payment;
    }
    this.isConfigured = true;
    this.initForm();
  }


  initForm() {
    this.TrxServicesForm = this.formBuilder.group({
      amount: [
        { value: this.amount ? this.amount : 0, disabled: true },
        [Validators.required],
      ],
      cvv: ['',Validators.required],
      card_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{12,20}$')],
      ],
      expiry_month: ['', [Validators.required]],
      expiry_year: ['', [Validators.required]],
      //Add address detail in form
      address_ln1: [
        this.debtorDetails?.addresses[0]?.address_ln1
          ? this.debtorDetails.addresses[0]?.address_ln1
          : '',
        [Validators.required],
      ],
      address_ln2: [
        this.debtorDetails?.addresses[0]?.address_ln2
          ? this.debtorDetails.addresses[0]?.address_ln2
          : '',
        [],
      ],
      address_ln3: [
        this.debtorDetails?.addresses[0]?.address_ln3
          ? this.debtorDetails.addresses[0]?.address_ln3
          : '',
        [],
      ],
      address_town: [
        this.debtorDetails?.addresses[0]?.address_town
          ? this.debtorDetails.addresses[0]?.address_town
          : '',
        [],
      ],
      address_postcode: [
        this.debtorDetails?.addresses[0]?.address_postcode
          ? this.debtorDetails.addresses[0]?.address_postcode
          : '',
        [Validators.required],
      ],
      //Add debtor name for form
      debtor_name: [
        this.debtorDetails?.debtor_name
          ? this.debtorDetails?.debtor_name
          : '',
        [Validators.required],
      ],
      //Add debtor email for form
      debtor_email: [
        this.debtorDetails?.debtor_email ? this.debtorDetails.debtor_email : '',
        [Validators.required],
      ],
      acceptTerms : [
        false,
      ]
    });
  }

  generateYearList(): number[] {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 16 }, (_, index) => currentYear + index);
    return years;
  }

  generateMonthList(): number[] {
    return Array.from({ length: 12 }, (_, index) => index + 1);
  }

  async proceedPayment() {
    if (this.TrxServicesForm.valid) {
      if(this.paymentGatewayTemplate && this.paymentGatewayTemplate.above_proceed && !this.TrxServicesForm.get('acceptTerms').value) {
        this.showAlert('Please agree to the terms and conditions.');
        return;
      }
      /*@see LU-171 for showing payment confirmation pop up */
      if(this.responseData?.confirmMessage) {
        const alert = await this.alertController.create({
          header: 'Please confirm your agreement to this fee.',
          message: `<ion-item>
                      <ion-label position="stacked">Enter Name</ion-label>
                      <ion-input name="name" type="text" placeholder="Enter your name..." required></ion-input>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-checkbox name="agreement" slot="start" value="agree"></ion-checkbox>
                      <ion-label>Agreed</ion-label>
                    </ion-item>`,
          cssClass:'custom-alert-class',
          buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {}
          },
          {
          text: 'OK',
            handler: (data) => {
            const nameInput = document.querySelector('ion-input[name="name"]') as HTMLIonInputElement;
            const agreementCheckbox = document.querySelector('ion-checkbox[name="agreement"]') as HTMLIonCheckboxElement;
            const name = nameInput?.value;
            const agreement = agreementCheckbox?.checked;
              if (name && agreement) {
                this.confirmPayment()
              } else {
              if (!name) {
                this.showAlert('Please Enter name.');
              } else if (!agreement) {
                this.showAlert('Please agree to the terms and conditions.');
              }
              return false; // Prevents the alert from closing
              }
            }
          }
        ]
        });
            await alert.present();
      } else {
        this.confirmPayment()
      }
    }
  }

  //show alert if name of aggrement checkbox is not checked
  async showAlert(message: string) {
      const alert = await this.alertController.create({
        header: 'Alert',
        message: message,
        buttons: ['OK']
      });
      await alert.present();
    }

  //paymet confirmation
  confirmPayment() {
      this.saveBtnDisabled = true;
      let obj = this.TrxServicesForm.value;
      obj.debtor_id = this.debtorDetails.debtor_id,
      [obj.debtor_first_name, obj.debtor_last_name] = obj.debtor_name.split(/ (.+)/);
      obj['gateway_id'] = this.responseData.id;
      this.caseService.makeTrxServicesPayment(this.caseId, obj).subscribe((res: any) => {
        this.saveBtnDisabled = false;
        this.commonService.dismissLoader();
        if (res.result) {
          this.commonService.showToast(res['message'], 'success');
          this.caseService.saveCaseDetails(localStorage.getItem('debtor_id'));
          this.ionNav.popToRoot();
        } else {
          this.commonService.showToast(res['message'], 'danger');
        }
      });
    }

  async dismiss() {
    this.ionNav.pop();
  }

  cancel() {
    this.dismiss();
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IonNav, AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { CaseService } from 'src/app/services/case.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-trxservices-ach',
  templateUrl: './trxservices-ach.page.html',
  styleUrls: ['./trxservices-ach.page.scss'],
})
export class TrxServicesAchPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  @Input() debtorDetails;
  @Input() totalOutstandingWithSymbol;
  @Input() caseDetails;
  amount;
  @Input() debtorId = localStorage.getItem('debtor_id');
  selectedCases = [];
  @Input() paymentMethod;
  addCardForm: FormGroup;
  datemin = moment().format('YYYY-MM-DD');
  datemax = moment().add('100', 'years').format('YYYY-MM-DD');
  savedCardList = [];
  company = '';
  surcharge_amount = 0;
  debtorDetailsSubscription: Subscription;
  caseDetailsSubscription: Subscription;
  paymentGatewayList = [];
  savedCards = [];
  isGroupArrangement = false;
  billingAddress = [];
  selectedBillingAddress;
  saveBtnDisabled = false;
  message = '';
  title = 'Lateral Payment Portal';
  paymentGatewayTemplate;

  paymentMethods = [
    { id: 1, label: 'Cash' },
    { id: 2, label: 'Cheque' },
    { id: 4, label: 'Credit card', name: 'card' },
    { id: 5, label: 'Debit Card', name: 'bank' },
    { id: 8, label: 'BACS' },
  ];
  method = null;
  outstanding;
  selectOptions = {};

  account_type_option = [{ id: 'checking', label: 'Checking' }, { id: 'saving', label: 'Saving' }];
  account_use_option = [{ id: 'personal', label: 'Personal' }, { id: 'Business', label: 'Business' }];

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private caseService: CaseService,
    private ionNav: IonNav,
    private alertController: AlertController,
  ) {}

  ngOnInit() {
    this.amount = parseFloat(this.responseData?.net_amount).toFixed(2);
    this.surcharge_amount = this.responseData?.surcharge_amount;
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    this.company = this.responseData.company;
    this.message = portalSettings.payment_type_message;
    if (
      portalSettings.payment_method_list &&
      Object.values(portalSettings.payment_method_list)
    ) {
      this.paymentMethods = [];
      const payment_method_list = portalSettings.payment_method_list;
      Object.keys(payment_method_list).forEach((key) => {
        this.paymentMethods.push({
          id: parseInt(key),
          label: payment_method_list[key],
        });
      });
    }
    if(portalSettings?.gateway_templates?.trxServices?.ach_non_recurring_payment){
      this.paymentGatewayTemplate = portalSettings.gateway_templates.trxServices.ach_non_recurring_payment;
    }
    this.method = this.paymentMethods[0].id
      ? this.paymentMethods[0].id.toString()
      : '';
    this.initForm();
  }
  initForm() {
    this.addCardForm = this.formBuilder.group({
      bank_routing_number: [
        '',
        [Validators.required],
      ],
      bank_account_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{9,20}$')],
      ],
      account_type: ['', [Validators.required]],
      account_use: ['', [Validators.required]],
      //Add address detail in form
      address_ln1: [
        this.debtorDetails?.addresses[0]?.address_ln1
          ? this.debtorDetails.addresses[0]?.address_ln1
          : '',
        [Validators.required],
      ],
      address_town: [
        this.debtorDetails?.addresses[0]?.address_town
          ? this.debtorDetails.addresses[0]?.address_town
          : '',
        [],
      ],
      address_ln3: [
        this.debtorDetails?.addresses[0]?.address_ln3
          ? this.debtorDetails.addresses[0]?.address_ln3
          : '',
        [],
      ],
      address_postcode: [
        this.debtorDetails?.addresses[0]?.address_postcode
          ? this.debtorDetails.addresses[0]?.address_postcode
          : '',
        [Validators.required],
      ],
      description: [''],
      //Add debtor name for form
      debtor_name: [
        this.debtorDetails?.debtor_name
          ? this.debtorDetails?.debtor_name
          : '',
        [Validators.required],
      ],
      //Add debtor email for form
      debtor_email: [
        this.debtorDetails?.debtor_email ? this.debtorDetails.debtor_email : '',
        [Validators.required],
      ],
      acceptTerms: [
        false,
      ]
    });
  }

  async save() {
    this.addCardForm.markAllAsTouched();
    if (this.addCardForm.valid) {
      if(this.paymentGatewayTemplate && this.paymentGatewayTemplate.above_proceed && !this.addCardForm.get('acceptTerms').value) {
        this.showAlert('Please agree to the terms and conditions.');
        return;
      }
      /*@see LU-171 for showing payment confirmation pop up */
      if (this.responseData?.confirmMessage) {
        const alert = await this.alertController.create({
          header: 'Please confirm your agreement to this fee.',
          message: `<ion-item>
                    <ion-label position="stacked">Your Name</ion-label>
                    <ion-input name="name" type="text" placeholder="Enter your name..." required></ion-input>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-checkbox name="agreement" slot="start" value="agree"></ion-checkbox>
                    <ion-label>Agreed</ion-label>
                  </ion-item>`,
          cssClass: 'custom-alert-class', //added custom alert class
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => { }
            },
            {
              text: 'OK',
              handler: (data) => {
                const nameInput = document.querySelector('ion-input[name="name"]') as HTMLIonInputElement;
                const agreementCheckbox = document.querySelector('ion-checkbox[name="agreement"]') as HTMLIonCheckboxElement;
                const name = nameInput?.value;
                const isAgreement = this.paymentGatewayTemplate.above_proceed === "" ? false : true;
                let agreement = true;
                if (isAgreement) {
                  agreement = agreementCheckbox?.checked;
                }
                if (name && agreement) {
                  this.confirmPayment();
                } else {
                  if (!name) {
                    this.showAlert('Please Enter name.');
                  } else if (!agreement) {
                    this.showAlert('Please agree to the terms and conditions.');
                  }
                  return false; // Prevents the alert from closing
                }
              }
            }
          ]
        });
        await alert.present();

      } else {
        this.confirmPayment();
      }
    }
  }

  //show alert if name of aggrement checkbox is not checked
  async showAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }

  //paymet confirmation
  confirmPayment() {
    var first_name, last_name;
    this.commonService.showLoader();
    [first_name, last_name] = this.addCardForm.value.debtor_name.split(/ (.+)/);
    const data = {
      gateway_id: this.responseData.id,
      amount: this.amount,
      description: this.addCardForm.value.description,
      routing: this.addCardForm.value.bank_routing_number,
      account: this.addCardForm.value.bank_account_number,
      account_type: this.addCardForm.value.account_type,
      account_use: this.addCardForm.value.account_use,
      debtor_first_name: first_name,
      debtor_last_name: last_name,
      debtor_id: this.debtorDetails.debtor_id,
      address_ln1: this.addCardForm.value.address_ln1,
      address_ln2: this.addCardForm.value.address_ln2,
      address_ln3: this.addCardForm.value.address_ln3,
      town: this.addCardForm.value.address_town,
      address_postcode: this.addCardForm.value.address_postcode,
    };
    this.caseService
      .maketrxAchRequest(this.caseId, data)
      .subscribe((res: any) => {
        this.saveBtnDisabled = false;
        this.commonService.dismissLoader();
        if (res.result) {
          this.commonService.showToast(
            'Action Performed Successfully!',
            'success'
          );
          this.caseService.saveCaseDetails(localStorage.getItem('debtor_id'));
          this.ionNav.popToRoot();
        } else {
          this.commonService.showToast(res['message'], 'danger');
        }
      });
  }

  async dismiss() {
    this.ionNav.pop();
  }

  cancel() {
    this.dismiss();
  }

}
